import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from "../components/PageComponents/Article/BMWProfessional3.js"
import localize from "../components/utils/localize.js"

const IndexPage = ({ location, pageContext, data }) => (
  <Layout location={location} context={pageContext}>
    <SEO title="article" />
    <Article sanity={data} />
  </Layout>
)

export default localize(IndexPage)

export const pageQuery = graphql`
  query ArticleBMWProfessionalVol3PageQuery {
    articleimg: file(name: { eq: "PA_01" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg2: file(name: { eq: "PA_06" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg2mobile: file(name: { eq: "RA_01B" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg3: file(name: { eq: "PA_05" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg4: file(name: { eq: "PA_10" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg4mobile: file(name: { eq: "RA_01C" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg5: file(name: { eq: "RA_02" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg5mobile: file(name: { eq: "RA_01" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanityHomePage {
      exploreSectionTitle {
        _type
        en
        tc
      }
      exploreSectionParagraph {
        _type
        en
        tc
      }
      formTitle {
        _type
        en
        tc
      }
      howToJoin {
        _type
        en
        tc
      }
      howToJoinStepOne {
        _type
        en
        tc
      }
      howToJoinStepThree {
        _type
        tc
        en
      }
      howToJoinStepTwo {
        _type
        en
        tc
      }
      joinSectionParagraph {
        _type
        en
        tc
      }
      joinSectionTitle {
        _type
        en
        tc
      }
      submit {
        _type
        en
        tc
      }
      adventureTitle {
        _type
        en
        tc
      }
      embrace {
        _type
        en
        tc
      }
      freedomTitle {
        _type
        en
        tc
      }
      independenceTitle {
        _type
        en
        tc
      }
      inspirationTitle {
        _type
        en
        tc
      }
      progressTitle {
        _type
        en
        tc
      }
      wisdomTitle {
        _type
        tc
        en
      }
    }
  }
`

