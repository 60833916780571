import React, { useRef, useEffect } from "react"
import styles from "./index.module.scss"
import Img from "gatsby-image"
import { initVimeoPlayerWithControl } from "../../utils"
import CTABtn from "../../Button/CTA"
import BackgroundImage from "gatsby-background-image"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import loadStates from "../../../hocs/loadStates"

function ArticleTwo({ sanity, lang: { locale } }) {
  const isChinese = locale === "tc"
  return (
    <div>
      <Header data={sanity} isChinese={isChinese} />
      <SectionOne data={sanity} isChinese={isChinese} />
      <SectionTwo data={sanity} isChinese={isChinese} />
      <SectionThree data={sanity} isChinese={isChinese} />
      <SectionFour data={sanity} isChinese={isChinese} />
      <SectionFive data={sanity} isChinese={isChinese} />
      <SectionSix data={sanity} />
      <SectionSeven data={sanity} isChinese={isChinese} />
      <SectionEight data={sanity} />
      <SectionNine data={sanity} isChinese={isChinese} />
      <SectionEleven data={sanity} isChinese={isChinese} />
      <SectionTwelve data={sanity} isChinese={isChinese} />
      <SectionThirdteen data={sanity} isChinese={isChinese} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

export default loadStates(ArticleTwo)

function Header({ data, isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/451037098/848055fadb",
        mobileVid: null,
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
    </section>
  )
}

function SectionOne({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <h2 className="quotes md:text-6xl bold-font mb-20 md:mb-10">
              90-Second with BMW Professional Vol. 3: BMW Personal CoPilot
            </h2>
            {isChinese ? (
              <p className="large-text">
                第3集90-second with BMW
                Professional由BMW專業人員為你帶來BMW的駕駛輔助功能小提示，包括泊車輔助功能和自動倒車輔助功能。透過90秒時間，認識BMW的智能配備，了解如何於香港日常街道活用這些功能，輕鬆駕馭各種情況。
              </p>
            ) : (
              <p className="large-text">
                Our BMW experts present you with the Driving assistance system,
                including parking assistant and reversing assistant. This
                episode focuses on BMW Personal CoPilot, allowing you to get a
                sense of BMW Driving Assistance and how to use these functions
                in practice.
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTwo({ data, isChinese }) {
  return (
    <section>
      <div className="image-container">
        <Img fluid={data.articleimg.childImageSharp.fluid} />
      </div>
    </section>
  )
}

function SectionThree({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "香港常見泊車位置主要分為「L」和「S」形。"
                    : "The most commonly seen parking spots in Hong Kong are Perpendicular Parking and Parallel Parking."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <div className="md:text-center">
                          有了BMW的泊車輔助功能，令停泊兩種車位都變得更輕鬆簡單。
                        </div>
                      ) : (
                        <div className="md:text-center">
                          With the Parking Assistant function from BMW, it makes
                          both parking easier and simpler.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text">
                  {isChinese
                    ? "當需要使用Parking Assistant 泊車操控輔助功能時，先按一下 PDC泊車系統感應器按鈕 ，啟動車位偵測功能，然後繼續向前慢駛。"
                    : "If you wish to use the Parking Assistant function, press the PDC button. This activates the parking space detection and continue driving forward slowly."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ data, isChinese }) {
  return (
    <section>
      <div className="image-container">
        <Img
          fluid={data.articleimg2.childImageSharp.fluid}
          className="md:hidden"
        />
        <Img
          fluid={data.articleimg2mobile.childImageSharp.fluid}
          className="hidden md:block"
        />
      </div>
    </section>
  )
}

function SectionFive({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "系統會開始偵測車輛兩旁適合停泊的位置。"
                    : "The system will automatically detect the two sides of the vehicle for a suitable parking space."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <h4 className="md:text-center">
                          注意一點:車身側面與車位需要保持1.5米以內的距離以開啟此輔助功能。
                        </h4>
                      ) : (
                        <h4 className="md:text-center">
                          A point to note, when detecting, there should be no
                          more than 1.5m between the side of the vehicle and the
                          parking space in order for the system to effectively
                          detect the location of the parking space.
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-16">
                <p className="large-text mb-32 md:mb-16">
                  {isChinese
                    ? "當系列成功偵測車位，選定停泊位置，然後可以鬆開煞車腳制和放開軑環，系統便會啟動自動泊車程序。 完成泊車後，系統會自動轉為停泊波檔。"
                    : "When the system has detected a suitable parking space, you can release the steering wheel and brake pedal, and the vehicle will automatically begin parking. Once the system has finished parking, the parking gear will be selected automatically."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ data }) {
  return (
    <section>
      <div className="image-container">
        <Img fluid={data.articleimg3.childImageSharp.fluid} />
      </div>
    </section>
  )
}

function SectionSeven({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "泊車輔助功能適用於「L」和「S」形車位。"
                    : "Parking Assistant applies to both Perpendicular Parking and Parallel Parking."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <h3 className="-mx-6">
                          如果想要離開泊車位，只要按下「停車輔助功能」，再按下箭咀，系統便會自動啟動程序。
                        </h3>
                      ) : (
                        <h3>
                          If you would like to get out of a parking spot, simply
                          press the Park Assistant button and arrow sign. The
                          system will then be activated.
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text mb-32 md:mb-16">
                  {isChinese
                    ? "車輛便會自動調較角度，移動到方便直接駛出的位置。"
                    : "The vehicle will automatically adjust its angle and move to a position that is convenient for driving directly out."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionEight({ data }) {
  return (
    <section>
      <div className="image-container">
        <Img
          fluid={data.articleimg4.childImageSharp.fluid}
          className="md:hidden"
        />
        <Img
          fluid={data.articleimg4mobile.childImageSharp.fluid}
          className="hidden md:block"
        />
      </div>
    </section>
  )
}

function SectionNine({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "另外，在日常駕駛時，亦有機會遇到不熟悉的道路，遇上路段的盡頭而需要原路倒車。"
                    : "On the other hand, in daily driving, we might drive in a new and unknown area, for instance like a narrow road or run into a dead end. We need to reverse the vehicle on the route it just travelled in a dead end."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <h3 className="md:text-center whitespace-no-wrap">
                          此時便能使用倒車輔助功能。
                        </h3>
                      ) : (
                        <h3 className="md:text-center">
                          The Reversing Assistant can help you steer while
                          reversing.
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text light-font">
                  {isChinese
                    ? "系統只會於向前行駛時速35公里以下自動紀錄最多50米路程。"
                    : "The system will only automatically record the steering for forward-moving driving up to 35km/h for a maximum of 50m."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionEleven({ data }) {
  return (
    <section>
      <div className="image-container">
        <Img
          fluid={data.articleimg5.childImageSharp.fluid}
          className="md:hidden"
        />
        <Img
          fluid={data.articleimg5mobile.childImageSharp.fluid}
          className="hidden md:block"
        />
      </div>
    </section>
  )
}

function SectionTwelve({ isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "當駕駛員轉用後波操作，並啟用倒車輔助功能，系統便會顯示已記錄的可倒車距離。"
                    : "When drivers are in reverse with Reversing Assistant activated, the vehicle icon will show the reversible distance recorded on the system."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <h3 className="md:text-center">
                          此時便可以鬆開軑環開始倒車。
                        </h3>
                      ) : (
                        <h3 className="md:text-center">
                          Driver can then release the steering wheel and begin
                          reversing.
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text light-font">
                  {isChinese
                    ? "過程中只需要控制車速以及留意周圍情況，並於有需要時隨時介入煞車。"
                    : "During the reverse, you only need to control the vehicle’s speed and be aware of the surrounding, and may stop the vehicle when there is a need."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThirdteen({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20 bg-black text-white">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full pr-20">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "BMW駕駛輔助功能為您提供更可靠的駕駛體驗，帶來較大的安全性和舒適性，令您專注享受純粹的駕駛樂趣。"
                    : "BMW Driving Assistance helps you in numerous ways during your journey and provide all-round comfort, allowing you to enjoy the sheer driving pleasure."}
                </h3>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="w-1/2 md:w-full">
                <div className="flex">
                  <hr className="w-64 h-1 bg-black mb-32" />
                </div>
                <p className="text-3xl">
                  {isChinese
                    ? "緊貼BMW資訊，為現在及未來做好規劃，開拓寬闊通道。更多資訊陸續 為您送上!"
                    : "Empower yourself with information. Stay tuned for our latest updates about Along with BMW and learn more about our BMW experience."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
